import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
}))

/*
fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
*/
const GatsbyImage = ({
  filename,
  alt = "",
  aspectRatio,
  className,
  style,
  imgStyle = {},
}) => {
  const classes = useStyles()
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            nodes {
              relativePath
              extension
              publicURL
              url
              name
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }

              # childCloudinaryAsset {
              #   id
              #   fluid(maxWidth: 1200) {
              #     ...CloudinaryAssetFluid
              #   }
              # }
            }
          }
        }
      `}
      render={data => {
        const image = data.images.nodes.find(n => {
          return n.relativePath.includes(filename)
        })

        if (image?.extension === "svg") {
          return (
            // <object
            //   data={image.publicURL}
            //   style={style}
            //   className={className}
            //   type="image/svg+xml"
            // >
            //   {alt}
            // </object>
            <img
              src={image.publicURL}
              alt={alt}
              style={style}
              className={className}
            />
          )
        }

        if (!image) {
          return null
        }

        const imageSizes = image.childImageSharp.fluid
        const fluid = { ...imageSizes }
        if (aspectRatio) {
          fluid.aspectRatio = aspectRatio
        }

        return (
          <Img
            className={clsx(classes.root, className)}
            alt={alt}
            fluid={fluid}
            imgStyle={imgStyle}
            style={style}
          />
        )
      }}
    />
  )
}

export default GatsbyImage
